import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Layout from 'components/layout';
import Title from 'components/title';
import Footer from 'components/footer/Footer';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const About = ({ data }) => (
  <Layout>
    <div className="layout-div">
      <Title as="h2" size="large">
        {data.aboutJson.en.title}
      </Title>
    </div>
    <div style={{ height: '40vh' }} >
      <CardContent>
        <Typography variant="subtitle1" paragraph>
          <div className="source-light" style={{fontSize:12}}
            dangerouslySetInnerHTML={{
              __html: data.aboutJson.en.content.childMarkdownRemark.html,
            }}
          />
        </Typography>
      </CardContent>
    </div>
    <div style={{ height: '20vh' }} />
    <Footer footers={data.homeJson.en.footers} />
  </Layout>
);

About.propTypes = {
  data: PropTypes.object.isRequired,
};

export default About;

export const query = graphql`
  query AboutEnQuery {
    aboutJson {
      en {
        title
        content {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    homeJson {
      en {
        footers {
          title
          description {
            label
            link
          }
        }
      }
    }
  }
`;
